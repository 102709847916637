import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useDocumentTitle} from "common/hooks/useDocumentTitle";
import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/api/apiProvider";
import {useHeaderContext} from "context/header-context";
import {FetchStatus, ValidatorResult} from "@fleet/common/hooks/useFetch";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Spinner} from "@bolteu/kalep-react";

import {ApplicationWizard} from "./wizard";

const fetchWizardDataFunction = (api: Api, query: VehicleMarketplaceService.ApplicationWizardDataRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationGetApplicationWizardData(query);
export const applicationUpdateFunction = (api: Api, body: VehicleMarketplaceService.UpdateApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationUpdate(body);
export const applicationPublishFunction = (api: Api, body: VehicleMarketplaceService.PublishApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationPublish(body);

export const ApplicationPage = () => {
    const {getListingPath} = useAbsolutePath();
    const navigate = useNavigate();
    useDocumentTitle("page_title.application");

    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);
    const [shouldShowSubmittedSuccessContent, setShouldShowSubmittedSuccessContent] = useState(false);
    const {setIsVisible: setHeaderIsVisible} = useHeaderContext();

    const params = useParams();
    const listingId = Number(params.listing_id);
    const applicationId = Number(params.application_id);

    const {fetch: update, status: updateStatus, error: updateError} = useFetch(applicationUpdateFunction);
    const {
        fetch: publish,
        status: publishStatus,
        error: publishError,
        data: publishData,
    } = useFetch(applicationPublishFunction);
    const {data: wizardData, fetch: fetchWizardData, status: wizardDataFetchStatus} = useFetch(fetchWizardDataFunction);

    useEffect(() => {
        setHeaderIsVisible(false);

        return () => {
            setHeaderIsVisible(true);
        };
    });

    useEffect(() => {
        if (updateError.validationErrors) {
            setValidatorResults(updateError.validationErrors);
        }
    }, [updateError.validationErrors]);

    useEffect(() => {
        if (publishError.validationErrors) {
            setValidatorResults(publishError.validationErrors);
        }
    }, [publishError.validationErrors]);

    useEffect(() => {
        if (updateStatus === FetchStatus.Success && publish) {
            publish({application_id: applicationId});
        }
    }, [updateStatus, publish, applicationId]);

    useEffect(() => {
        if (publishStatus === FetchStatus.Success) {
            setShouldShowSubmittedSuccessContent(true);
        }
    }, [publishStatus]);

    const onClose = useCallback(() => {
        navigate(getListingPath(listingId));
    }, [navigate, getListingPath, listingId]);

    useEffect(() => {
        if (fetchWizardData) {
            fetchWizardData({application_id: applicationId});
        }
    }, [fetchWizardData, applicationId]);

    if (wizardDataFetchStatus === FetchStatus.Loading || wizardDataFetchStatus === FetchStatus.Init) {
        return (
            <Page>
                <div className="mt-20 w-full text-center">
                    <Spinner />
                </div>
            </Page>
        );
    }

    if (
        Number.isNaN(listingId) ||
        Number.isNaN(applicationId) ||
        wizardDataFetchStatus === FetchStatus.Error ||
        !wizardData
    ) {
        return (
            <Page>
                <ErrorView type={ErrorViewType.NotFound} className="!bg-layer-floor-0-grouped" />
            </Page>
        );
    }

    const isSubmitLoading = updateStatus === FetchStatus.Loading || publishStatus === FetchStatus.Loading;

    return (
        <ApplicationWizard
            applicationId={applicationId}
            listingData={wizardData.listing_data}
            listingTranslations={wizardData.listing_translations}
            wizardFields={wizardData.wizard_fields}
            feedbackLink={wizardData.support_link}
            listingImageUrl={wizardData.listing_images[0].thumb_400_url}
            onClose={onClose}
            isSubmitLoading={isSubmitLoading}
            validatorResults={validatorResults}
            shouldShowSubmittedSuccessContent={shouldShowSubmittedSuccessContent}
            submit={update}
            listingId={listingId}
            companyDetails={publishData?.company_details}
        />
    );
};
