import React, {useCallback, useContext, useEffect, useState} from "react";

import {useAuthentication} from "common/hooks/useAuthentication";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {ThirdPartyContextProvider} from "common/providers/appThirdPartyProvider";

import {requestNewAccessToken} from "../authService";
import {AppApiClient} from "./api_clients/AppApiClient";
import {AppJwtAuthStrategy} from "./strategies/AppJwtAuthStrategy";

export type Api = AppApiClient;

const vehicleMarketplaceApi = new AppApiClient();

const ApiContextProvider = React.createContext<AppApiClient | null>(null);

const ApiProvider = ({children}: {children: React.ReactNode}) => {
    const {observability} = useContext(ThirdPartyContextProvider);
    const appState = useContext(ApplicationContextProvider);

    const {makeLogout} = useAuthentication();

    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [isAccessTokenError, setIsAccessTokenError] = useState(false);

    let apiValue: Api | null = null;

    useEffect(() => {
        async function initAccessToken() {
            if (!appState.refreshToken) {
                setAccessToken(null);
                return;
            }

            try {
                const newAccessToken = await requestNewAccessToken(appState.refreshToken);
                setAccessToken(newAccessToken);
            } catch (e) {
                observability.reportError(e as Error);
                setIsAccessTokenError(true);
            }
        }
        initAccessToken();
    }, [appState.refreshToken, observability]);

    useEffect(() => {
        if (isAccessTokenError) {
            setIsAccessTokenError(false);
            makeLogout();
        }
    }, [isAccessTokenError, makeLogout]);

    const getAccessToken = useCallback(() => accessToken, [accessToken]);

    const authTokenParseDidThrow = useCallback(async () => {
        await makeLogout();
    }, [makeLogout]);

    const updateAccessToken = useCallback(async () => {
        if (!appState.refreshToken) {
            observability.reportError(new Error("No refresh token provided"));
            setIsAccessTokenError(true);
            return Promise.reject(new Error("No refresh token provided"));
        }

        try {
            const newAccessToken = await requestNewAccessToken(appState.refreshToken);
            setAccessToken(newAccessToken);
            return Promise.resolve(newAccessToken);
        } catch (e) {
            observability.reportError(e as Error);
            setIsAccessTokenError(true);
            return Promise.reject(e);
        }
    }, [appState.refreshToken, observability]);

    if (accessToken) {
        const authStrategy = new AppJwtAuthStrategy(getAccessToken, updateAccessToken, authTokenParseDidThrow);
        vehicleMarketplaceApi.init(authStrategy);

        apiValue = vehicleMarketplaceApi;
    }
    return <ApiContextProvider.Provider value={apiValue}>{children}</ApiContextProvider.Provider>;
};

export {ApiProvider, ApiContextProvider};
