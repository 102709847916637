import {useI18n} from "common/hooks/useI18n";
import {TextButton} from "@fleet/common/components/TextButton";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Chip, Link, Typography} from "@bolteu/kalep-react";
import {Check} from "@bolteu/kalep-react-icons";

import {canRequestVehicle} from "../util";
import {DetailsCard} from "./DetailsCard";
import {PriceWithRequestVehicle} from "./PriceWithRequestVehicle";

interface Props {
    data: VehicleMarketplaceService.GetListingPartnerResponse;
    requestVehicleClicked: () => void;
}

export const ListingCards = ({data, requestVehicleClicked}: Props) => {
    const {i18n} = useI18n();
    const {data: listingData, translations: listingTranslations} = data.listing_details;

    const vehicleFields =
        listingTranslations.vehicle_specifics_fields?.values ??
        ({} as Record<string, VehicleMarketplaceService.StringTranslation>);
    const terms =
        listingTranslations.applicant_terms?.values ??
        ({} as Record<string, VehicleMarketplaceService.StringTranslation>);

    const maintenanceLabel = listingTranslations.pricing_fields?.values.maintenance.key ?? "";
    const insuranceLabel = listingTranslations.pricing_fields?.values.insurance.key ?? "";
    const hasInsurance = listingData.pricing_fields?.insurance === VehicleMarketplaceService.Insurance.YES;
    const hasMaintanance = listingData.pricing_fields?.maintenance === VehicleMarketplaceService.Maintenance.YES;
    const insuranceClasses = hasInsurance ? "" : "[&>span:last-of-type>span]:line-through";
    const maintananceClasses = hasMaintanance ? "" : "[&>span:first-of-type>span]:line-through";

    const hasDepositSum = Boolean(listingData.pricing_fields?.deposit_sum);
    const hasInsuranceDeductibleSum = Boolean(listingData.pricing_fields?.deductible_sum);
    const hasRentToBuyOfferType = listingData.offer_type === VehicleMarketplaceService.OfferType.RENT_TO_BUY;
    const hasPricingExtraInfo = hasDepositSum || hasRentToBuyOfferType;

    const isRequestVehicleButtonShown = canRequestVehicle(data.application_details?.status);

    return (
        <>
            <DetailsCard>
                <PriceWithRequestVehicle
                    listingData={listingData}
                    requestVehicleClicked={requestVehicleClicked}
                    isRequestVehicleButtonShown={isRequestVehicleButtonShown}
                />
                {hasPricingExtraInfo && <div className="border-separator h-px w-full border-b" />}
                {hasRentToBuyOfferType && (
                    <div>
                        <Typography>{i18n("listing.rent_to_buy_pricing")}</Typography>
                        <Typography fontSize="text-sm" color="secondary">
                            {i18n("listing.ask_owner_for_details")}
                        </Typography>
                    </div>
                )}
                {hasDepositSum && (
                    <div className="flex items-center justify-between">
                        <div>
                            <Typography>{i18n("listing.deposit_required")}</Typography>
                            <Typography fontSize="text-sm" color="secondary">
                                {i18n("listing.ask_owner_for_details")}
                            </Typography>
                        </div>
                        <span>{`${listingData.pricing_fields?.deposit_sum}${listingData.currency_symbol}`}</span>
                    </div>
                )}
            </DetailsCard>
            <DetailsCard title={i18n("listing.whats_included")}>
                <div
                    className={`[&>span>span]:text-primary flex flex-wrap gap-2 [&>span>span]:overflow-visible ${insuranceClasses} ${maintananceClasses}`}
                >
                    <Chip label={maintenanceLabel} startIcon={hasMaintanance ? <Check /> : undefined} />
                    <Chip label={insuranceLabel} startIcon={hasInsurance ? <Check /> : undefined} />
                </div>
                {hasInsuranceDeductibleSum && (
                    <ul className="mt-2 list-disc pl-6 text-sm">
                        <li>{`${i18n("listing.insurance_deductible")}: ${listingData.pricing_fields?.deductible_sum}${
                            listingData.currency_symbol
                        }`}</li>
                    </ul>
                )}
            </DetailsCard>
            <DetailsCard title={i18n("listing.full_tech_details")}>
                <ul className="list-disc pl-6">
                    {Object.keys(vehicleFields).map((key) => {
                        if (key === "vehicle_colors" || key === "vehicle_years") {
                            return null;
                        }
                        return <li key={key}>{`${vehicleFields[key].key}: ${vehicleFields[key].value}`}</li>;
                    })}
                </ul>
            </DetailsCard>
            {Object.keys(terms).length !== 0 && (
                <DetailsCard title={i18n("listing.driving_requirements")}>
                    <ul className="list-disc pl-6">
                        {Object.keys(terms).map((key) => (
                            <li key={key}>{`${terms[key].key}: ${terms[key].value}`}</li>
                        ))}
                    </ul>
                </DetailsCard>
            )}
            <DetailsCard title={i18n("listing.owner_details")} hasAction={!data.company_details.contacts}>
                <>
                    <ul className="list-disc pl-6">
                        {[data.company_details.name, data.company_details.city_name].map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                        {data.company_details.contacts && (
                            <>
                                {data.company_details.contacts.email && (
                                    <li>
                                        <Link href={`mailto:${data.company_details.contacts.email}`}>
                                            {data.company_details.contacts.email}
                                        </Link>
                                    </li>
                                )}
                                {data.company_details.contacts.phone && (
                                    <li>
                                        <Link href={`tel:${data.company_details.contacts.phone}`}>
                                            {data.company_details.contacts.phone}
                                        </Link>
                                    </li>
                                )}
                            </>
                        )}
                    </ul>
                    {!data.company_details.contacts && (
                        <div className="flex flex-wrap gap-1">
                            <p>{i18n("listing.apply_for_contacts_question")}</p>
                            <TextButton
                                onClick={requestVehicleClicked}
                                text={i18n("listing.apply_for_contacts_link_text")}
                            />
                        </div>
                    )}
                </>
            </DetailsCard>
        </>
    );
};
