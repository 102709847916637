import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import Details from "./Details";
import Pricing from "./Pricing";
import VehiclePicture from "./VehiclePicture";

import BodyType = VehicleMarketplaceService.CarModelBodyType;

interface Props {
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
    listingImageUrl?: string;
}

export const VehicleDetailsSidebar = ({listingData, listingTranslations, listingImageUrl}: Props) => (
    <div className="bg-layer-surface flex flex-col gap-6 overflow-y-auto rounded-lg px-6 pt-4 pb-10">
        <VehiclePicture
            listingImageUrl={listingImageUrl}
            vehicleModelBodyType={listingData.model_body_type ?? BodyType.SEDAN}
        />
        <div className="flex flex-col gap-12">
            <Details listingData={listingData} listingDataTranslations={listingTranslations} />
            <Pricing listingData={listingData} listingDataTranslations={listingTranslations} />
        </div>
    </div>
);
