import {useEffect} from "react";

import config from "config/index";
import {TranslationKeys} from "config/translations";

import {useI18n} from "./useI18n";

const getDocumentTitle = (viewTitle: string) =>
    `${viewTitle} | ${config.appBrand.charAt(0).toUpperCase()}${config.appBrand.slice(1).toLowerCase()}`;

export const useDocumentTitle = (translationKey: TranslationKeys) => {
    const {i18n} = useI18n();

    useEffect(() => {
        document.title = getDocumentTitle(i18n(translationKey));
    }, [i18n, translationKey]);
};
