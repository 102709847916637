import {Suspense} from "react";
import {Route, Routes} from "react-router-dom";

import VehicleListingsLayout from "app/layouts/VehicleListingsLayout";
import {AppRoute} from "constants/routes";

import {Spinner} from "@bolteu/kalep-react";

import {ApplicationPage} from "./application";
import ListingPage from "./listing";
import ListingsPage from "./listings/list";
import {Redirect} from "./Redirect";

export const AppRoutes = () => (
    <Suspense fallback={<Spinner size={700} />}>
        <Routes>
            <Route path={AppRoute.CITY_PREFIX} element={<VehicleListingsLayout />}>
                <Route index element={<ListingsPage />} />
                <Route path={AppRoute.LISTING}>
                    <Route index element={<ListingPage />} />
                    <Route path={AppRoute.APPLICATION_CREATE} element={<ApplicationPage />} />
                    <Route path="*" element={<Redirect />} />
                </Route>
                <Route path="*" element={<Redirect />} />
            </Route>
            <Route path="*" element={<Redirect />} />
        </Routes>
    </Suspense>
);
