import React, {useCallback} from "react";

import {LocalStorageKeys, localStorageService} from "common/services/LocalStorageService";
import config from "config/index";
import defaultTranslations from "config/locales/en-US/translations.json";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";
import {sentryInit} from "@fleet/common/services/sentry";
import {DefaultLocale, TranslationService} from "@fleet/common/services/translations";

type Translations = typeof defaultTranslations;
const fallbackTranslations: Record<string, Translations> = {
    [DefaultLocale]: defaultTranslations,
};

export interface Observability {
    reportError: (e: Error) => void;
    addBreadcrumb: (name: string, metadata?: Record<string, unknown>) => void;
    setUser: (id: string) => void;
}

export interface ThirdPartyState {
    changeLocale: (locale: string) => Promise<void>;
    observability: Observability;
}

const ThirdPartyContextProvider = React.createContext<ThirdPartyState>({
    changeLocale: () => Promise.resolve(),
    observability: {
        reportError: () => {},
        addBreadcrumb: () => {},
        setUser: () => {},
    },
});
ThirdPartyContextProvider.displayName = "ThirdPartyContextProvider";

const ThirdPartyProvider = ({children}: {children: React.ReactNode}) => {
    const [currentlySelectedLocale, setLocale] = useLocalStorage(
        localStorageService.getItemKey(LocalStorageKeys.LOCALE),
        DefaultLocale,
    );

    const {ErrorBoundary, reportError, setUser, addBreadcrumb} = sentryInit(
        config.sentryDataSourceName,
        config.isEnvLocal,
        "vehiclemarketplace",
        config.versionNumber,
        config.appStage,
        config.appBrand,
    );

    const onTranslationsFetchError = useCallback((e: Error) => reportError(e), [reportError]);

    const {RemoteIntlProvider, changeAppLocale} = TranslationService(
        config.baseUrl,
        config.crowdinFolder,
        config.defaultLocale,
        currentlySelectedLocale,
        config.crowdinBackendProject,
        config.crowdinFileName,
        fallbackTranslations,
    );

    const changeLocale = useCallback(
        async (locale: string) => {
            setLocale(locale);
            await changeAppLocale(locale);
        },
        [changeAppLocale, setLocale],
    );

    return (
        <ErrorBoundary>
            <RemoteIntlProvider onTranslationsFetchError={onTranslationsFetchError} key={config.defaultLocale}>
                <ThirdPartyContextProvider.Provider
                    value={{
                        changeLocale,
                        observability: {
                            reportError,
                            addBreadcrumb,
                            setUser,
                        },
                    }}
                >
                    {children}
                </ThirdPartyContextProvider.Provider>
            </RemoteIntlProvider>
        </ErrorBoundary>
    );
};

export {ThirdPartyContextProvider, ThirdPartyProvider};
