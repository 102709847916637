import {useCallback, useContext} from "react";

import LanguageSelector from "common/components/languageSelector";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useAuthentication} from "common/hooks/useAuthentication";
import {useI18n} from "common/hooks/useI18n";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {useHeaderContext} from "context/header-context";

import {Button} from "@bolteu/kalep-react";

import LogoLink from "./LogoLink";

const Header = () => {
    const appState = useContext(ApplicationContextProvider);
    const {redirectToDriverPortalForLogin, makeLogout} = useAuthentication();
    const {getHomePath} = useAbsolutePath();
    const {i18n} = useI18n();
    const {isVisible} = useHeaderContext();

    const onAuthenticate = useCallback(() => {
        redirectToDriverPortalForLogin();
    }, [redirectToDriverPortalForLogin]);

    if (!isVisible) {
        return null;
    }

    return (
        <nav className="bg-layer-floor-0-grouped inset-x-0 top-0 z-[25] px-6 md:px-16">
            <div className="flex h-full flex-col justify-between">
                <div className="flex justify-between py-4">
                    <LogoLink to={getHomePath()} />
                    <div className="flex gap-4">
                        <LanguageSelector />
                        {appState.isAuthenticated ? (
                            <Button onClick={makeLogout} size="sm" variant="secondary">
                                {i18n("auth.logout")}
                            </Button>
                        ) : (
                            <Button onClick={onAuthenticate} size="sm">
                                {i18n("auth.login")}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
