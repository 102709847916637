import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Severity} from "@bolteu/kalep-react/build/components/Alert/Alert.types";

import ApplicationStatus = VehicleMarketplaceService.ApplicationStatus;

interface Props {
    application: VehicleMarketplaceService.ApplicationDetails | null;
    vehicleName: string;
    onCancelRequest: () => void;
}

interface ActionType {
    onClick: () => void;
    label: string;
}

export const isAlertShown = (status?: VehicleMarketplaceService.ApplicationStatus | null) =>
    !!status &&
    [
        ApplicationStatus.PUBLISHED,
        ApplicationStatus.IN_PROGRESS,
        ApplicationStatus.REJECTED,
        ApplicationStatus.DECLINED,
        ApplicationStatus.APPROVED,
    ].includes(status);

export const useApplicationAlert = ({
    application,
    vehicleName,
    onCancelRequest,
}: Props): null | {title: string; description: string; action?: ActionType; severity: Severity} => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getHomePath} = useAbsolutePath();

    const goToListings = useCallback(() => {
        navigate(getHomePath());
    }, [navigate, getHomePath]);

    if (!application) {
        return null;
    }

    switch (application.status) {
        case ApplicationStatus.PUBLISHED:
        case ApplicationStatus.IN_PROGRESS:
            return {
                title: i18n("listing.alert.request_sent_title"),
                description: i18n("listing.alert.request_sent_description"),
                action: {
                    onClick: onCancelRequest,
                    label: i18n("listing.alert.request_sent_action"),
                },
                severity: "info",
            };
        case ApplicationStatus.REJECTED:
        case ApplicationStatus.DECLINED: {
            return {
                title: i18n("listing.alert.request_declined_title"),
                description: application.rejection_reason ?? i18n("listing.alert.request_declined_description"),
                action: {
                    onClick: goToListings,
                    label: i18n("listing.alert.request_declined_action"),
                },
                severity: "error",
            };
        }
        case ApplicationStatus.APPROVED:
            return {
                title: i18n("listing.alert.request_approved_title"),
                description: i18n("listing.alert.request_approved_waiting_description", {vehicleName}),
                severity: "success",
            };
        case ApplicationStatus.DRAFT:
        case ApplicationStatus.INACTIVE:
            return null;
        default:
            return UnreachableCode.never(application.status, null);
    }
};
