import {Link, LinkProps} from "react-router-dom";

import Logo from "assets/icons/logo.svg?react";

export interface LogoLinkProps {
    to: LinkProps["to"];
}

const LogoLink = ({to}: LogoLinkProps) => (
    <Link to={to} className="block pt-1">
        <Logo className="h-8 translate-y-1 scale-y-105 md:h-7 md:translate-y-0 md:scale-y-100" />
    </Link>
);

export default LogoLink;
