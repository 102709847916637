import {useCallback, useState} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {AdditionalFields, FormValueType} from "@fleet/common/components/dynamic_form/types";
import {
    getApiToFormValues,
    getFilteredAndSortedFields,
    getFormField,
    getFormToApiValues,
} from "@fleet/common/components/dynamic_form/util";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";
import {getDateFormat, getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {VehicleMarketplaceService as VehicleMarketplaceServiceFOP} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Button, DatePicker, Typography} from "@bolteu/kalep-react";

import {FeedbackLink} from "../components/FeedbackLink";

import FormField = VehicleMarketplaceServiceFOP.FormField;
import UpdateApplicationRequest = VehicleMarketplaceService.UpdateApplicationRequest;

interface Props {
    fields: FormField[];
    applicationId: number;
    onClose: () => void;
    submit: ((request: UpdateApplicationRequest) => Promise<void>) | null;
    isSubmitLoading: boolean;
    feedbackLink?: string;
    validatorResults: ValidatorResult[];
}

const WizardContentHeader = ({title, subtitle}: {title: string; subtitle?: string}) => (
    <div className="flex flex-col gap-6">
        <Typography variant="title-primary" fontSize="text-3xl">
            {title}
        </Typography>
        {subtitle && <Typography variant="body-primary">{subtitle}</Typography>}
    </div>
);

const WizardContent = ({
    fields,
    applicationId,
    onClose,
    isSubmitLoading,
    submit,
    feedbackLink,
    validatorResults,
}: Props) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const [startRentingDate, setStartRentingDate] = useState<Date>(new Date());

    const formValue = getApiToFormValues(fields);
    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue);

    const {Form, Button: FormButton} = useFormValue;

    const onSubmitForm = useCallback(
        async (state: FormState<FormValueType>) => {
            if (!submit) {
                return;
            }

            const {data} = state;
            await submit({
                applicant_requirement_answers:
                    getFormToApiValues<VehicleMarketplaceService.ApplicantRequirementAnswers>(data, fields),
                application_id: applicationId,
                planned_start_date: getIsoDate(startRentingDate),
            });
        },
        [fields, applicationId, startRentingDate, submit],
    );

    const onStartRentingDateChange = useCallback(
        (date: Date | Date[] | null) => {
            if (date && !Array.isArray(date)) {
                setStartRentingDate(date);
            }
        },
        [setStartRentingDate],
    );

    return (
        <Form validatorResults={validatorResults} onSubmit={onSubmitForm}>
            <div className="flex w-full flex-col gap-8">
                <div className="flex max-w-full flex-col gap-6">
                    {getFilteredAndSortedFields(fields).map((field) => (
                        <div key={field.name} className="w-full">
                            <div className="w-3/4">{getFormField(field, validatorResults, useFormValue)}</div>
                        </div>
                    ))}
                </div>
                <div className="w-[240px]">
                    <DatePicker
                        label={i18n("application_wizard.rental_requirements.start_renting_date_picker_title")}
                        value={startRentingDate}
                        onChange={onStartRentingDateChange}
                        format={getDateFormat(intl)}
                        minDate={new Date()}
                        locale={intl.locale}
                        fullWidth
                    />
                </div>
                <div className="text-secondary bolt-font-body-s-regular">
                    <p>{i18n("application_wizard.rental_requirements.submit_request_note")}</p>
                    <FeedbackLink feedbackLink={feedbackLink} />
                </div>
                <div className="flex gap-4">
                    <Button variant="secondary" onClick={onClose} disabled={isSubmitLoading}>
                        {i18n("application_wizard.rental_requirements.back_button_text")}
                    </Button>
                    <FormButton submit loading={isSubmitLoading}>
                        {i18n("application_wizard.rental_requirements.submit_request_button_text")}
                    </FormButton>
                </div>
            </div>
        </Form>
    );
};

export default WizardContent;
export {WizardContentHeader};
