import {BrowserRouter as Router} from "react-router-dom";

import {AppInit} from "app/AppInit";
import {ApplicationProvider} from "common/providers/appStateProvider";
import {ThirdPartyProvider} from "common/providers/appThirdPartyProvider";
import {ApiProvider} from "common/services/api/apiProvider";
import {NotificationProvider} from "@fleet/common/services/notificationProvider";

const App: React.FC = () => (
    <ThirdPartyProvider>
        <NotificationProvider>
            <ApplicationProvider>
                <Router>
                    <ApiProvider>
                        <AppInit />
                    </ApiProvider>
                </Router>
            </ApplicationProvider>
        </NotificationProvider>
    </ThirdPartyProvider>
);

export default App;
